import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";

dayjs.extend(relativeTime);

const SearchListItem = ({ chatBot, onUpdate, clearSearch }) => {
  const [user, setUser] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const key = chatBot.image

//   useEffect(() => {
//     const fetchUser = async () => {
//       const authUser = await Auth.currentAuthenticatedUser();
//       setUser(authUser.attributes.sub)
//     }
//     fetchUser();
//   }, [])
  
  const onPress = async (event) => {
    onUpdate(chatBot)
    clearSearch()
  }

  return (
    <Container style={styles.container}>
            <Row onClick={onPress} style={styles.rowStyle}>
                <Col style={styles.imageWidth}>
                    <img
                        src={key} // Replace with the URL of your image
                        alt="Chatbot" // Provide an alternative description for accessibility
                        style={styles.image} // Set the width and height of the image
                    />
                </Col>
                <Col style={styles.name}>
                    <p style={styles.namePStyle} title={chatBot.name}>{chatBot.name}</p>
                </Col>
                <Col style={styles.subTitle}>
                    <p style={styles.subtitlePStyle} title={chatBot.status}>{chatBot.status}</p>
                </Col>
            </Row>
    </Container>
  );
};

const styles = {
  container: {
    //display: 'flex',
    backgroundColor: 'white',
    borderBottom: '2px solid lightgrey',
    //flex: '0 0 100%',
    //borderRadius: '5px',
    //justifyContent: 'center',
  },
  rowStyle: {
    //display: 'flex',
    //flexDirection: 'row',
    alignItems: 'center',
    //backgroundColor: 'yellow',
    //justifyContent: 'flex-start',
    //flex: '0 0 100%',
  },
  imageWidth: {   
    flex: '0 0 10%',
    //backgroundColor: 'red',
    },
  image: {
    width: 45,
    height: 45,
    borderRadius: 30,
    margin: 5,
    resizeMode: 'cover',
    backgroundColor: 'white',
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    height: '100%',
    flex: '0 0',
    //backgroundColor: 'blue',
    margin: 5,
    whiteSpace: 'nowrap',
  },
  namePStyle: {
    fontSize: 20,
    fontWeight: 'bold',
    flexDirection: 'column',
    flex: '0 0 100%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 5,
  },
  subtitlePStyle: {
    fontSize: 16,
    // italic

    //fontWeight: 'bold',
    //flexDirection: 'column',
    flex: '0 0 100%',
    //alignItems: 'center',
    //justifyContent: 'center',
    margin: 5,
  },
  subTitle: {
    //display: 'flex',
    color: 'gray',
    height: '100%',
    alignItems: 'center',
    //justifyContent: 'center',
    flex: '1 0 40%',
    fontStyle: 'italic',
    //backgroundColor: 'green',
  },
};

export default SearchListItem;