import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Button } from '@aws-amplify/ui-react';


const ChatspediaNavbar = ({ signOut, user, chatbots, onUpdate, handleSignOut }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionID, setSelectedOptionID] = useState(null);
    const [chatBotList, setChatBotList] = useState(null);

    let filteredChatbots = chatbots.data.chatBotsByUserID.items.filter((chatbot) => !chatbot._deleted)

    useEffect(() => {
        setChatBotList(filteredChatbots)
    }, [chatbots])
    
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionSelect = (option) => {        
        setSelectedOption(option.name);
        setSelectedOptionID(option.id);
        setIsOpen(false);
        onUpdate(option);
      };

    return (
        <Navbar bg="dark" variant="dark" style={styles.navbar}>
            <Container>
              <Navbar.Brand as={Link} to="/">
                <img
                  alt=""
                  src="/icons8-bot-100.png"
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                />{' '}
                Chatspedia
              </Navbar.Brand>
              <Nav className="me-auto" style={styles.flexParent}>
                {/* <Nav.Link as={Link} to="/data">Data</Nav.Link>
                <Nav.Link as={Link} to="/prompts">Prompts</Nav.Link> */}
                { chatBotList ? (
                <Navbar.Collapse id="navbar-nav">
                    <NavDropdown
                        title={ selectedOption || 'Select existing chatbot' }
                        id="nav-dropdown"
                        show={isOpen}
                        onToggle={handleToggle}
                        renderMenuOnMount
                    >
                        {chatBotList.map((option) => (
                            <NavDropdown.Item key={option.name} onClick={() => handleOptionSelect(option)} style={styles.NavBarDropdownText}>
                            {option.name}
                            </NavDropdown.Item>
                        ))}
                    </NavDropdown>
                </Navbar.Collapse>
                ) : null }

                <Nav.Link as={Link} to="/" style={styles.buildNewChatbotButton}>Build new chatbot</Nav.Link> */}

              </Nav>
              <Container style={styles.navbar}>
              <Navbar.Collapse className="justify-content-end mx-auto">
                <Navbar.Text>
                  Signed in as: <Navbar.Text style={{color:"white"}}>{user.username}</Navbar.Text>
                </Navbar.Text>
                <Button style={styles.button} onClick={() => {
                  signOut();
                  handleSignOut();
                }}>Sign out</Button>
              </Navbar.Collapse>
              </Container>
            </Container>
          </Navbar>
    )
}

const styles = {
    button: {
      marginLeft: 30,
      paddingLeft: "300",
      backgroundColor: "lightblue"
    },
    dropdown: {
        backgroundColor: "lightblue"
    },
    dropdownMenu: {
        marginLeft: 30,
        paddingLeft: "300",
        backgroundColor: "white",
        color: "black"
    },
    buildNewChatbotButton: {
      flex: '0 0 auto'
    },
    flexParent: {
      display: "flex",
    },
    navbar: {
      height: '7vh'
    },
  }

export default ChatspediaNavbar;