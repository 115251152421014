import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Button } from '@aws-amplify/ui-react';


const ChatspediaNavbar2 = ({ handleSignIn }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionID, setSelectedOptionID] = useState(null);
    const [chatBotList, setChatBotList] = useState(null);

    //let filteredChatbots = chatbots.data.chatBotsByUserID.items.filter((chatbot) => !chatbot._deleted)

    // useEffect(() => {
    //     setChatBotList(filteredChatbots)
    // }, [chatbots])
    
    // const handleToggle = () => {
    //     setIsOpen(!isOpen);
    // };

    // const handleOptionSelect = (option) => {        
    //     setSelectedOption(option.name);
    //     setSelectedOptionID(option.id);
    //     setIsOpen(false);
    //     onUpdate(option);
    //   };

    return (
        <Navbar bg="dark" variant="dark" style={styles.navbar}>
            <Container>
              <Navbar.Brand as={Link} to="/">
                <img
                  alt=""
                  src="/icons8-bot-100.png"
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                />{' '}
                Chatspedia
              </Navbar.Brand>
              <Navbar.Collapse style={styles.signInButton} >
                {/* <Button style={styles.button} onClick={handleSignIn}>Sign in</Button> */}
              </Navbar.Collapse>
            </Container>
          </Navbar>
    )
}

const styles = {
    button: {
      marginLeft: 30,
      paddingLeft: "300",
      backgroundColor: "lightblue"
    },
    navbarBrand: {
        fontSize: '60px'
    },
    dropdown: {
        backgroundColor: "lightblue"
    },
    dropdownMenu: {
        marginLeft: 30,
        paddingLeft: "300",
        backgroundColor: "white",
        color: "black"
    },
    buildNewChatbotButton: {
      flex: '0 0 auto'
    },
    flexParent: {
      display: "flex",
    },
    navbar: {
      height: '7vh',
    },
    signInButton: {
        justifyContent: 'flex-end',
        marginRight: 10
    },
  }

export default ChatspediaNavbar2;