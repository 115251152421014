import { Container } from "react-bootstrap";
import React, { useState } from "react";
import FormSection from "../components/FormSection";
import MessageSection from "../components/MessageSection";
import FormSection2 from "../components/FormSection2";

const ChatScreen = ({ cBot, uid }) => {

    return (
        <Container style={styles.container}>
            {/* <MessageSection cBot={cBot} uid={uid} />
            <FormSection cBot={cBot} uid={uid} /> */}
            <FormSection2 cBot={cBot} uid={uid} />
        </Container>
    )
}

const styles = { 
    container: {
        height: '75%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        //justifyContent: 'flex-end',
        //backgroundColor: 'red'
    },

  };

  

export default ChatScreen;