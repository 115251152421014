import  { React, useState, useEffect } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { API, graphqlOperation } from 'aws-amplify';
import { updateChatBot } from '../graphql/mutations';
import { chatBotsByUserID } from '../graphql/queries';


const PromptsPage = ({ authUser, cBot }) => {
    const [instruction, setInstruction] = useState('');
    const [example, setExample] = useState('');
    const [chatBot, setChatBot] = useState(null);
    const [firstMessage, setFirstMessage] = useState('');

    useEffect(() => {
        if (!chatBot) {
            setChatBot(cBot)
        }
      }, [chatBot, cBot])

    const handleInstructionChange = (event) => {
        setInstruction(event.target.value);
    };

    const handleExampleChange = (event) => {
        setExample(event.target.value);
    };

    const handleFirstMessageChange = (event) => {
        setFirstMessage(event.target.value);
        console.log(event.target.value)
    };

    const handleExampleSubmit = async (event) => {
        event.preventDefault();
        try {            
          await API.graphql(graphqlOperation(updateChatBot, {
            input: {
              id: chatBot.data.chatBotsByUserID.items[0].id,
              example: example,
              _version: chatBot.data.chatBotsByUserID.items[0]._version
            }
          }))

          const chatBotData = await API.graphql(graphqlOperation(chatBotsByUserID, { 
            userID: authUser
          }))

          setChatBot(chatBotData)
          
        } catch (error) {
          console.error('Error updating chatbot with example', error);
        }
        
        
        setExample('');
      }

    const handleInstructionSubmit = async (event) => {
        event.preventDefault();
        try {            
            await API.graphql(graphqlOperation(updateChatBot, {
                input: {
                    id: chatBot.data.chatBotsByUserID.items[0].id,
                    instruction: instruction,
                    _version: chatBot.data.chatBotsByUserID.items[0]._version
                }
            }));

            const chatBotData = await API.graphql(graphqlOperation(chatBotsByUserID, { 
                userID: authUser
              }))
            setChatBot(chatBotData)            
        } catch (error) {
          console.error('Error updating chatbot with instruction', error);
        }
        setInstruction('');
    };

    const handleFirstMessageSubmit = async (event) => {
        event.preventDefault();
        console.log("Entered handleFirstMessageSubmit")
        try {            
            await API.graphql(graphqlOperation(updateChatBot, {
                input: {
                    id: chatBot.data.chatBotsByUserID.items[0].id,
                    initialPrompt: firstMessage,
                    _version: chatBot.data.chatBotsByUserID.items[0]._version
                }
            }));

            const chatBotData = await API.graphql(graphqlOperation(chatBotsByUserID, { 
                userID: authUser
              }))
            setChatBot(chatBotData)   
            console.log("chatBotData: ", chatBotData)         
        } catch (error) {
          console.error('Error updating chatbot with instruction', error);
        }
        setFirstMessage('');
    };

    return (
    <Container>
        {chatBot ? (
            <div>
                <h1>{chatBot.data.chatBotsByUserID.items[0].name} Prompts</h1>
                <p>
                    Prompts are used to customize chatbot behavior when responding to queries. 
                    You can modify the <span style={{fontStyle: "italic"}}>Instructions</span> and <span style={{fontStyle: "italic"}}>Examples</span> for your chatbot's prompt.
                </p>
                <p> 
                    <span style={{fontWeight: "bold"}}>Instructions</span> provide detail on specific tasks that the model should perform. Suggestions to create strong instructions:
                </p>
                <ul>
                    <li><span style={{fontWeight: "bold"}}>Use commands</span> like "Answer", "Write", "Summarize", "Translate", or "Order"</li>
                    <li><span style={{fontWeight: "bold"}}>Tell the chatbot what <span style={{fontStyle: "italic"}}>to do</span></span> rather than what <span style={{fontStyle: "italic"}}>not to do</span></li>
                    <li><span style={{fontWeight: "bold"}}>Be specific</span> about the task you want the chatbot to perform</li>
                    <li><span style={{fontWeight: "bold"}}>Keep it short</span> and use 1-2 sentence instructions</li>   
                </ul>
                <p> All chatbots start with these default intructions: xx</p>
                <p>Update the instructions using this text box:</p>
                <Form onSubmit={handleInstructionSubmit}>
                    <Form.Group controlId="instruction">
                        {/* <Form.Label>Instructions:</Form.Label> */}
                        <Form.Control
                        as="textarea"
                        value={instruction}
                        onChange={handleInstructionChange}
                        rows={5}
                        placeholder={chatBot.data.chatBotsByUserID.items[0].instruction}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit Instruction
                    </Button>
                </Form>
                <p> 
                    <br></br>          
                    <span style={{fontWeight: "bold"}}>Examples:</span><span style={{fontStyle: "italic"}}> </span>
                </p>
                <Form onSubmit={handleExampleSubmit}>
                    <Form.Group controlId="example">
                        {/* <Form.Label>Example(s):</Form.Label> */}
                        <Form.Control
                        as="textarea"
                        value={example}
                        onChange={handleExampleChange}
                        rows={5}
                        placeholder={chatBot.data.chatBotsByUserID.items[0].example}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit Example
                    </Button> 
                </Form>
                <p> 
                    <br></br>          
                    <span style={{fontWeight: "bold"}}>First Message</span><span style={{fontStyle: "italic"}}> </span>
                </p>
                <Form onSubmit={handleFirstMessageSubmit}>
                    <Form.Group controlId="firstMessage">
                        {/* <Form.Label>Example(s):</Form.Label> */}
                        <Form.Control
                        as="textarea"
                        value={firstMessage}
                        onChange={handleFirstMessageChange}
                        rows={5}
                        placeholder={chatBot.data.chatBotsByUserID.items[0].initialPrompt}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit First Message
                    </Button>
                    
                </Form>
            </div>
        ) : (
             <h1>Loading...</h1>
        )}
    </Container>
    );
};
    
export default PromptsPage;



