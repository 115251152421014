import { Container, Image } from 'react-bootstrap';
import { React, useState, useEffect } from 'react';
import FileTable from '../components/FileTable';
import ChatBotForm from '../components/CreateChatBotForm';
import { Storage, API, graphqlOperation } from 'aws-amplify';
import { updateChatBot } from '../graphql/mutations';
import { getChatBot } from '../graphql/queries';
import { SearchField } from '@aws-amplify/ui-react';
import { searchChatBots } from '../graphql/queries';
import SearchListItem from '../components/SearchListItem';
import MessageSection from '../components/MessageSection';
import FormSection2 from '../components/FormSection2';
import ChatScreen from '../components/ChatScreen';
import FormSection3 from '../components/FormSection3';

function HomePage({ onUpdate, selectedOption }) {
    // home page will have four components
    // 1/ chatbot search bar
    // 2/ display selected chatbot
    // 3/ Chat conversation message section [need to integrate ad here]
    // 4/ Chat conversation input section

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const [chatBot, setChatBot] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    let logo = process.env.PUBLIC_URL + '/icons8-bot-100.png';

    useEffect(() => {
        console.log("xxx selectedOption: ", selectedOption)
        if (selectedOption !== undefined) {
            setChatBot(selectedOption)
            console.log("set chatbot: ", selectedOption)
            setIsButtonDisabled(false)
            console.log("set button to false")
        }
    }, [selectedOption])

    const onChange = (event) => {
        setSearchQuery(event.target.value);
        console.log("xxxxx: ", event.target.value)
        onUpdate(event.target.value);
      };
    
    // It is your responsibility to set up onClear
    const onClear = () => {
        setSearchQuery('');
        console.log("cleared search bar")
    };

    const handleClick = (event) => {
        console.log(event)
    }

    const clearSearch = (event) => {
        console.log(event)
        setSearchResults(null)
        setSearchQuery("")
    }



///////////////////////////////////////////////////////////////


const searchChatbot = async (event) => {
    var result
    setSearchQuery(event.target.value);
    console.log(event.target.value)
    
    if (event.target.value==="") {
      setSearchResults(null)
      return
    }
    
    if (event.target.value != "") {
      // let wc = "*" + input + "*"
      let wc = event.target.value + "*"

      // search chatbots by name
      result = await API.graphql(
        graphqlOperation(searchChatBots, { 
          filter: 
            {name: 
              {wildcard: wc}
            }
          } 
        )
      )

      const pre_items = result.data.searchChatBots.items

      
      console.log("prefiltered chatbots: ", pre_items)

      //const items = pre_items.filter(obj => !excludeIds.includes(obj.id));
      let items0 = pre_items.filter((chatbot) => !chatbot._deleted)
      let items = items0.filter(obj => obj.published===true);
  
      console.log("postfiltered chatbots: ", items)
      
      async function processBots() {
        for (let i=0; i<items.length; i++) {
          const imageUrl = await API.graphql(
            graphqlOperation(getChatBot, { id: items[i].id })
          )
          // set chatbot image
          items[i].image=imageUrl.data?.getChatBot?.image
          items[i].status=imageUrl.data?.getChatBot?.status
        }
      }
  
      processBots().then(() => {
        if (items.length > 0 ) {
          console.log(items)
          setSearchResults(items)
        } else {
          setSearchResults(null)
        }   
      }).then(() => {
        console.log("search results: ", searchResults)
      })
    }
  }



////////////////////////////////////////////////////////////////



    return (
        <Container style={styles.container}>
            {/* <h1 style={{fontWeight:'bold'}}>Welcome to Chatspedia!</h1>
            <p>
              Find chatbots e.g., by celebrity, brand, or city
              <br />
              Chat with them like you are texting a friend!            
            </p> */}
            { (!selectedOption || selectedOption.name==="") ? (
            <Container style={styles.logoContainer}>
                <Image src={logo} style={styles.image}/>
                <h1>Chatspedia</h1>
            </Container>
            ) : null }
            <Container style={styles.searchFieldContainer}>
                <SearchField 
                    label="search"
                    onChange={searchChatbot}
                    onClear={onClear}
                    value={searchQuery} 
                    placeholder='Search for a chatbot e.g., by celebrity, brand, or city'
                    size="large"
                    style={styles.searchField}
                />
                { searchResults!== null && searchResults.length > 0 ? 
                    <div style={styles.mappedComponent}>
                        {searchResults.map((item) => (
                            <SearchListItem chatBot={item} onUpdate={onUpdate} clearSearch={clearSearch}/>
                        ))}
                    </div>    
                : null
                }
            </Container>
            <br />


            {/* <ChatScreen chatBot={chatBot} onUpdate={onUpdate}/> */}
            { selectedOption.id !== '' ?
            <FormSection3 cBot={chatBot} isButtonDisabled={isButtonDisabled} chatbotID={chatBot.id} style={styles.formSection} />
            : null
            }
        </Container>
    )
}


const styles = { 
    container: {
        display: 'flex',
        flexDirection: 'column',
        //backgroundColor: 'lightgrey',
        marginTop: '0px',
        //margin: '10px',
        paddingTop: '50px',
        padding: '10px',
        position: 'relative',
        //height: '100%',
        textAlign: 'center',
        backgroundColor: 'lightblue',
        height: '93vh'
    },
    mappedComponent: {
        position: 'relative', 
        backgroundColor: 'lightblue',
        //display:'flex', 
        //flexDirection:'column'
        justifyContent: 'center',
    },
    button: {
      backgroundColor: 'black',
      margin: '5px',
      color: 'white',
      fontWeight: 'bold',
      border: 'none',
    },
    formSection: {
        //height: '25%',
        width: '100%',
        position: 'absolute',
        //bottom: 0,
        top: 0,
        left:0
        //flex: 1,
    },
    answerSection: {
        height: '75%',
        //width: '100%',
        //flex: 1,
        //flexDirection: 'row',
        //backgroundColor: 'red',
        display: 'flex',
        flexDirection: 'column',  
        overflowY: 'scroll',
    },
    searchField: {
        //width: '50%',
        backgroundColor: 'white',
    },
    searchFieldContainer: {
        width: '50%',
        textAlign: 'left',
        //position: 'relative',
        backgroundColor: 'lightblue',
        display: 'flex',
        flexDirection: 'column',
        flex: '0 0 5%',
    },
    logoContainer: {
        flex: '0 0 15%',
        
    },
    image: {
        width: '100px',
        height: '100px',
        alignSelf: 'center',
    },
  };



export default HomePage;