import React, { useState, useEffect } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { chatBotsByUserID } from "../graphql/queries";
import { Container } from "react-bootstrap";
import { BrowserRouter as Router } from "react-router-dom";
import HomePage from "../pages/Homepage";
import ChatspediaNavbar from "./AuthenticatedNavbar";
import ChatbotsPage from '../pages/Chatbots.js';
import DataPage from '../pages/Data';
import PromptsPage from '../pages/Prompts';
import { Routes, Route } from 'react-router-dom'
import { withAuthenticator } from "@aws-amplify/ui-react";


const AuthenticatedApp = withAuthenticator(({ cBotData, handleNavbarUpdate, selectedOption, handleSignOut, signOut, user }) => {
    const [loading, setLoading] = useState(null);
    const [aUser, setAUser] = useState(null);
    const [chatBotData, setChatBotData] = useState(null);

    useEffect(() => {
        setLoading(true)
        const syncUser = async () => {
            // get Auth user
            const authUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
            setAUser(authUser.username)

            // query the database using Auth user id (sub)
            // const userData = await API.graphql(graphqlOperation(getUser, { id: authUser.username }))

            // use chatBotsByUserId query to get chatBots
            //const chatBotData = await API.graphql(graphqlOperation(chatBotsByUserID, { userID: authUser.username }))
            const chatBotData = await API.graphql(graphqlOperation(chatBotsByUserID, { userID: authUser.attributes.sub }))
            
            // set chatBot state
            if (chatBotData?.data?.chatBotsByUserID?.items?.length > 0) {
                setChatBotData(chatBotData)
            }

            console.log("chatBotData: ", chatBotData)
            console.log("chatbotlist: ", chatBotData.data.chatBotsByUserID.items)

        }
        syncUser()
        setLoading(false)
    }, [])
  
    return (
        <div> 
            { (loading===true || chatBotData===null || chatBotData===undefined) ? (
                <div>
                <p>Loading...</p>
                </div>
            ) : (
                <Router>
                    <ChatspediaNavbar signOut={signOut} user={user} chatbots={chatBotData} onUpdate={handleNavbarUpdate} handleSignOut={handleSignOut}/>
                    <Routes>
                        <Route path="/" element={<HomePage onUpdate={handleNavbarUpdate} selectedOption={selectedOption}  />} />
                        <Route path="/data" element={<DataPage authUser={aUser} />} />
                        <Route path="/prompts" element={<PromptsPage authUser={aUser} />} />
                    </Routes>
                </Router>
        )}
        </div>
    )
    }
)


const styles = {
    headerText: {
        //marginTop: 0,
        textAlign: 'center',
        backgroundColor: 'lightblue',
        height: '93vh'
      }
};

export default AuthenticatedApp;