import { Container } from 'react-bootstrap';
import { React, useState, useEffect } from 'react';
import ChatbotTable from '../components/ChatbotTable';
import ChatBotForm from '../components/CreateChatBotForm';


function ChatbotsPage({ authUser, cBot }) {
  const [aUser, setAUser] = useState(null);
  const [chatBot, setChatBot] = useState(null);
  const [createChatbotView, setCreateChatbotView] = useState(false);
  
  useEffect(() => {
    setAUser(authUser)

    if (cBot?.data?.chatBotsByUserID?.items?.length > 0) {
      setChatBot(cBot)
    }
  }, [authUser, cBot, createChatbotView])
  
  return <Container>
        {chatBot && createChatbotView===false ? (
          <div>
            <ChatbotTable aUser={aUser}/>
            {/* use the code below when we want to expose ability to create another chatbot
            <div style={{display: 'flex', alignItems:'center'}}>
              <h3>Want to create another chatbot?</h3> &nbsp; &nbsp;
              <Button onClick={() => handleCreateChatbot()}>Click here</Button>
            </div> */}
          </div>
        ) : chatBot && createChatbotView===true ? (
          <div>
            <ChatbotTable aUser={aUser}/>
            {/* use the code below when we want to expose ability to create another chatbot
            <div style={{display: 'flex', alignItems:'center'}}>
              <h3>Want to create another chatbot?</h3> &nbsp; &nbsp;
              <Button onClick={() => handleCloseCreateChatbot()}>Close</Button>
            </div>
            <ChatBotForm user={aUser}/> */}
          </div>
        ) : (
          <div>
            <div>
              <h1>Data--</h1>
            </div>
            <ChatBotForm user={aUser}/>
          </div>
        )}
      </Container>
    }

export default ChatbotsPage;