/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://k675s64wara4hejrsyuwv3lxf4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-gwnqej6hdbeydnsfdotchuxsfe",
    "aws_cloud_logic_custom": [
        {
            "name": "chatapi",
            "endpoint": "https://hw6gtsmx24.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:1f1ce5c7-55b9-4778-a8fb-9eeb58c2a9b3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_2nblIqBh5",
    "aws_user_pools_web_client_id": "20h2p0042j1cc79fq2sivqvns9",
    "oauth": {
        "domain": "chatapped4d691b-ed4d691b-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "exp://192.168.4.89:19000,chatspedia://",
        "redirectSignOut": "exp://192.168.4.89:19000,chatspedia://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "chatbotdocs175600-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
