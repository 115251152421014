import { Amplify, Auth, API, graphqlOperation } from 'aws-amplify';
import awsConfig from './aws-exports';
import './App.css';
import { useEffect, useState } from "react";
import { chatBotsByUserID } from "./graphql/queries";
import { withAuthenticator } from '@aws-amplify/ui-react';
import styles from '@aws-amplify/ui-react/styles.css';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import PromptsPage from './pages/Prompts';
import DataPage from './pages/Data';
import ChatbotsPage from './pages/Chatbots.js';
import ChatspediaNavbar from './components/AuthenticatedNavbar.js';
import ChatspediaNavbar2 from './components/UnauthenticatedNavbar.js';
import HomePage from './pages/Homepage';
import UnauthenticatedApp from './components/UnauthenticatedApp';
import AuthenticatedApp from './components/AuthenticatedApp';

Amplify.configure({ ...awsConfig, Analytics: { disabled: true } })

function App() {
  const [aUser, setAUser] = useState(null);
  const [chatBot, setChatBot] = useState(null);
  const [chatBotData, setChatBotData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [selectedOption, setSelectedOption] = useState({name: '', id: ''});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [goToAuthentication, setGoToAuthentication] = useState(false);

  useEffect(() => {
    setLoading(true)
    checkAuthStatus();
    setLoading(false)
  }, []);

  ////////// NOTE - AUTHENTICATION CODE BELOW WILL LIKELY MOVE TO ANOTHER PAGE. 
  ////////// NEED TO KEEP IT THOUGH!!

  // useEffect(() => {
  //   setLoading(true)
  //   const syncUser = async () => {
  //     // get Auth user
  //     const authUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
  //     setAUser(authUser.username)

  //     // query the database using Auth user id (sub)
  //     // const userData = await API.graphql(graphqlOperation(getUser, { id: authUser.username }))

  //     // use chatBotsByUserId query to get chatBots
  //     //const chatBotData = await API.graphql(graphqlOperation(chatBotsByUserID, { userID: authUser.username }))
  //     const chatBotData = await API.graphql(graphqlOperation(chatBotsByUserID, { userID: authUser.attributes.sub }))
      
  //     // set chatBot state
  //     if (chatBotData?.data?.chatBotsByUserID?.items?.length > 0) {
  //        setChatBotData(chatBotData)
  //     }

  //     console.log("chatBotData: ", chatBotData)
  //     console.log("chatbotlist: ", chatBotData.data.chatBotsByUserID.items)

  // }
  // syncUser()
  // setLoading(false)
  // }, [selectedOption])

  const handleNavbarUpdate = (s) => {
    console.log("selectedOptionInput: ", s)
    console.log("s.name: ", s.name)
    console.log("s.id: ", s.id)
    setSelectedOption((prevState) => ({ 
      ...prevState,
      name: s.name, 
      id: s.id 
    }))
  }

  const handleGoToSignIn = async () => {
    console.log("signing in!!")
    try {
      //console.log("signed in!!")
      //await Auth.signIn({ redirect: true });
      setGoToAuthentication(true)
    } catch (error) {
      console.log("error signing in", error);

    }
  }

  const handleSignOut = async () => {
    try {
      setGoToAuthentication(false)
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  const checkAuthStatus = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);

      // sync user 
      const syncUser = async () => {
          // get Auth user
          const authUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
          setAUser(authUser.username)
    
          // query the database using Auth user id (sub)
          // const userData = await API.graphql(graphqlOperation(getUser, { id: authUser.username }))
    
          // use chatBotsByUserId query to get chatBots
          //const chatBotData = await API.graphql(graphqlOperation(chatBotsByUserID, { userID: authUser.username }))
          const chatBotData = await API.graphql(graphqlOperation(chatBotsByUserID, { userID: authUser.attributes.sub }))
          
          // set chatBot state
          if (chatBotData?.data?.chatBotsByUserID?.items?.length > 0) {
              setChatBotData(chatBotData)
          }
    
          console.log("chatBotData: ", chatBotData)
          console.log("chatbotlist: ", chatBotData.data.chatBotsByUserID.items)
        }
      syncUser()

    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  console.log("selectedOptionOutput: ", selectedOption)
  console.log("navbar update: ", selectedOption)

  return (
    <div style={styles.container}>
      { !goToAuthentication ? (
        <UnauthenticatedApp handleSignIn={handleGoToSignIn} handleNavbarUpdate={handleNavbarUpdate} selectedOption={selectedOption}/>
      ) : (
        <AuthenticatedApp cBotData={chatBotData} handleNavbarUpdate={handleNavbarUpdate} aUser={aUser} selectedOption={selectedOption} handleSignOut={handleSignOut} />
      )
    }
    </div>
  );   
}

const style = {
  headerText: {
    //marginTop: 0,
    textAlign: 'center',
    backgroundColor: 'lightblue',
    height: '93vh'
  }
}


export default App;
//export default withAuthenticator(App);

