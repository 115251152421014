import { Container } from 'react-bootstrap';
import { React, useState, useEffect } from 'react';
import FileTable from '../components/FileTable';
import ChatBotForm from '../components/CreateChatBotForm';
import { Storage, API, graphqlOperation } from 'aws-amplify';
import { updateChatBot } from '../graphql/mutations';
import { getChatBot } from '../graphql/queries';

function DataPage({ authUser, cBot }) {
  const [filesList, setFilesList] = useState([]);
  const [aUser, setAUser] = useState(null);
  const [chatBot, setChatBot] = useState(null);
  const [currentChatBot, setCurrentChatBot] = useState(null);
  
  useEffect(() => {
    setAUser(authUser)

    if (cBot?.data?.chatBotsByUserID?.items?.length > 0) {
      setChatBot(cBot)
    }

    API.graphql(graphqlOperation(getChatBot, {id: cBot?.data?.chatBotsByUserID?.items[0]?.id}))
    .then(result => {
      setCurrentChatBot(result)
    }
    )
  }, [authUser, cBot, chatBot, currentChatBot])

  function handleFileChange(event) {
    setFilesList(event.target.files)
  }

  async function handleFileUpload() {
      try {
        const metadata = {
          'userID': aUser, // Replace with the actual user ID
          'chatBotID': chatBot.data.chatBotsByUserID.items[0].id, // Replace with the actual chatBot ID
        };

        // for (let f in filesList) {
        for (var i = 0; i < filesList.length; i++) {
          var f = filesList[i];
        
          const result1 = await Storage.put(f.name, f, {
            // private means that users can only see their own documents
            level: 'private',
            progressCallback(progress) {
              console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            },
            metadata: metadata,
          })

          const chatbot = {
            id: chatBot.data.chatBotsByUserID.items[0].id,
            documents: result1.key,
            hasDocuments: true,
          }
          const result3 = await API.graphql(graphqlOperation(updateChatBot, {input: chatbot}))
          setChatBot(result3)
        }
      } catch (err) {
        console.log(err)
    }
}


  return <Container>
        {currentChatBot ? (
          <div>
            {/* only use the code below when we expose functionality for one use to create multiple chatbots
            <select value={selectedValue} onChange={handleSelectChange}>
              loop through chatbots
              { chatBot.data.chatBotsByUserID.items.map((chatBot, index) => (
                <option key={index} value={chatBot.id}>{chatBot.name}</option>
              ))
              }
            </select> */}
            <div>
              { currentChatBot.data.getChatBot.name ? (
                <h1>{currentChatBot.data.getChatBot.name}</h1>
              ) : (
                <h1>{chatBot.data.updateChatBot.name}</h1>
              )}
            </div>
            <div>
              {/* <p>The documents listed on this page will be used by {chatBot.data.chatBotsByUserID.items[0].name} to 
              respond to questions. You can view, add, and delete documents here. 
              </p> */}
              <p>The documents listed on this page will be used by {currentChatBot.data.getChatBot.name} to 
              respond to questions. You can view, add, and delete documents here. 
              </p>
              <ul>
                <li>new and updated docs will be immediately available to your chatbot</li>
                <li>deleted documents will no longer be available to your chatbot</li>
              </ul>
            </div>
            <div>
              <h2>Add a document</h2>
              <input type="file" multiple onChange={handleFileChange} />
              <button onClick={handleFileUpload}>Upload File</button>
            </div>
            <FileTable aUser={aUser} cBot={currentChatBot} />
          </div>
        ) : (
          <div>
            <div>
              <h1>Data--</h1>
            </div>
            <ChatBotForm user={aUser} />
          </div>
        )}
      </Container>
    }

export default DataPage;