import { useState } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { createChatBot, updateChatBot, deleteChatBot, createChatRoom } from '../graphql/mutations';
import { chatBotsByUserID, getChatBot } from '../graphql/queries';
import { useEffect } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { listChatBotNames } from '../graphql/queries';
import AnswerSection from './AnswerSection';
import AnswerSectionHeader from './AnswerSectionHeader';
import FormSection from './FormSection';
import ChatScreen from './ChatScreen';
import FileTable from './FileTable';
//import Data from '../pages/Data';


function ChatBotForm( { sOption } ) {
  const [authUser, setAuthUser] = useState(null);
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [status, setStatus] = useState('');
  const [persona, setPersona] = useState('');
  const [instruction, setInstruction] = useState('');
  const [example, setExample] = useState('');
  const [chatBotList, setChatBotList] = useState([]);
  const [chatBot, setChatBot] = useState(null);
  const [firstMessage, setFirstMessage] = useState('');
  const [published, setPublished] = useState(false);
  const [dbFunction, setDbFunction] = useState('');


  const [selectedChatbotID, setSelectedChatbotID] = useState(null);
  const [selectedChatbotName, setSelectedChatbotName] = useState(null);

  const [loading, setLoading] = useState(false);
  const [chatRoom, setChatRoom] = useState(null);
  
  useEffect(() => {
    async function setVariables() {
      try {
        setSelectedChatbotID(sOption.id);
        console.log("setVariables sOption.id: ", sOption.id)
        setSelectedChatbotName(sOption.name);
        return "set variables success"
      } catch (error) {
        console.log("error: ", error)
      }
    }

    async function fetchUser() {
        try {
          const result = await setVariables();
          console.log("set variables result: ", result)
          const authUser = await Auth.currentAuthenticatedUser();
          setAuthUser(authUser);
          console.log("fetchuser authUser: ", authUser)
          return authUser.attributes.sub
        } catch (error) {
          console.log("error: ", error)
        }
      }

    async function getChatBots() {
      try {
        const result = await fetchUser();

        console.log("fetch user result: ", result)
          
        const chatBotData = await API.graphql(graphqlOperation(chatBotsByUserID, { 
              userID: result
          }))
        setChatBotList(chatBotData)
        return "success"
      } catch (error) {
        console.log("error: ", error)
      }
    }

    async function getCB() {
      try {
        const result = await getChatBots();
        console.log("getChatBots result: ", result)
        console.log("entered getCB with sOption.id: ", sOption.id)
        //const result = await fetchUser();

        //console.log("fetch user result: ", result)

        let gcb
        let data

        if (sOption.id === null) {
          console.log("selectedChatbotID is null")
          return;
        } else {
          console.log("gcb selectedChatbotID is not null")
          gcb = await API.graphql(graphqlOperation(getChatBot, {
            id: sOption.id
          }))
          console.log("returned gcb")

          console.log("gcb: ", gcb)
        
          data = gcb.data.getChatBot
          setChatBot(data)

          if (data.published) {
            setPublished(data.published)
          }

          console.log("chatbot: ", gcb)
          }
        return data
      } catch (error) {
        return "gcb error" + JSON.stringify(error)
      }
    }

    async function setCBAttributes() {
      try {
        const result = await getCB();

        console.log("getCB result: ", result)

        if (result.name !== undefined && result.name !== null) {
          setName(result.name);
        } 

        if (result.image !== undefined && result.image !== null) {
          setImage(result.image);
        } 

        if (result.status !== undefined && result.status !== null) {
          setStatus(result.status);
        } 

        if (result.persona !== undefined && result.persona !== null) {
          setPersona(result.persona);
        } else {
          console.log("result.persona is null")
        }

        if (result.instruction !== undefined && result.instruction !== null) {
          setInstruction(result.instruction);
        } 

        if (result.example !== undefined && result.example !== null) {
          setExample(result.example);
        } 

        if (result.firstMessage !== undefined && result.firstMessage !== null) {
          setFirstMessage(result.firstMessage);
        } 

        console.log("set chatbot attributes")

        return "success"
      } catch (error) {
        console.log("error: ", error)
      }
    }

    setCBAttributes()
  }, [sOption, name, published, selectedChatbotID, chatRoom])

  function handleNameChange(event) {
    setName(event.target.value);
  }

  function handleImageChange(event) {
    setImage(event.target.value);
  }

  function handleStatusChange(event) {
    setStatus(event.target.value);
  }

  function handlePersonaChange(event) {
    setPersona(event.target.value);
  }

  function handleInstructionChange(event) {
    setInstruction(event.target.value);
  }

  function handleExampleChange(event) {
    setExample(event.target.value);
  }

  function handleDbFunctionChange(event) {
    setDbFunction(event.target.value);
  }

  function handleFirstMessageChange(event) {
    setFirstMessage(event.target.value);
  }

  async function handleSave(event) {
    event.preventDefault();

    // get a list of names of all chatbots
    let names = await API.graphql(graphqlOperation(listChatBotNames));

    // make sure the name is not already taken
    // if the name is taken, alert the user and do not create the chatbot
    // need to test the code below to make sure it works
    for (let i = 0; i < names.data.listChatBots.items.length; i++) {
        if (names.data.listChatBots.items[i].name === name) {
            alert('That chatbot name is already taken. Please choose another name.');
            return;
        }
    }

    // if the name is not taken, create the chatbot
    try {
        const newChatbot = await API.graphql(graphqlOperation(createChatBot, {
          input: {
            name,
            status,            
            image: image,
            persona,
            instruction,
            example,
            initialPrompt: firstMessage,
            userID: authUser.attributes.sub,
            published: false
          }
        }
        ));

        // create new chatroom with the new chatbot & the chatbot owner
        const newChatroom = await API.graphql(graphqlOperation(createChatRoom, {
          input: {
            chatbotID: newChatbot.data.createChatBot.id,
            userID: authUser.attributes.sub,
          }
        }
        ));

        setChatRoom(newChatroom.data.createChatRoom.id)

    } catch (error) {
        console.error('Error creating chatbot', error);
    }
  }

  async function handleUpdate(event) {
    try {
        const update = await API.graphql(graphqlOperation(updateChatBot, {
          input: {
            id: selectedChatbotID,
            _version: chatBot._version,
            name: name,
            status: status,            
            image: image,
            // persona: persona,
            instruction: instruction,
            example: example,
            //initialPrompt: firstMessage,
            //userID: authUser.username,
          }
        }));

        alert("Chatbot updated successfully!")

    } catch (error) {
        console.error('Error publishing chatbot', error);
    }
  }

  // the function below is not updating the published attribute
  // troubleshoot in the morning
  async function handlePublish(event) {
    try {
        const publish = await API.graphql(graphqlOperation(updateChatBot, {
          input: {
            id: selectedChatbotID, 
            _version: chatBot._version,
            published: true
          }
        }));
        setPublished(true)
    } catch (error) {
        console.error('Error publishing chatbot', error);
    }
  }

  async function handleUnpublish(event) {
    try {
        const unpublish = await API.graphql(graphqlOperation(updateChatBot, {
          input: {
            id: selectedChatbotID, 
            _version: chatBot._version,
            published: false
          }
        }));
        setPublished(false)
    } catch (error) {
        console.error('Error unpublishing chatbot', error);
    }
  }

  async function handleDelete(event) {
    try {
        const d = await API.graphql(graphqlOperation(deleteChatBot, {
          input: {
            // use the current chatbot id and set it to the id variable below
            id: selectedChatbotID,
            _version: chatBot._version,
          }
        }));
        setChatBot(null)
        setSelectedChatbotID(null)

        alert("Chatbot deleted successfully!")
    } catch (error) {
        console.error('Error deleting chatbot', error);
    }
  }

  return (
      <Container style={styles.parentContainer}>
        <Container style={styles.createChatbotContainer}>
          <Container style={styles.titleContainer}>
            <h3 style={{fontWeight:'bold'}}>Build Chatbot</h3>
          </Container>
          <Container style={styles.createChatbotBody}>
            <Form style={styles.createChatbotBodyForm}>
              <Container style={styles.formContainerOverflow}>
                <Form.Group style={styles.formMargin}>
                  <Form.Label style={styles.formBoldText}>Chatbot Name</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={name} 
                      onChange={handleNameChange} 
                      placeholder={"Enter chatbot name"}
                    />
                  <Form.Text>Users will be able to find your chatbot by searching for this name</Form.Text>
                </Form.Group>
                <Form.Group style={styles.formMargin}>
                  <Form.Label style={styles.formBoldText}>Image URL</Form.Label>
                  <Form.Control type="text" value={image} onChange={handleImageChange} rows="2" placeholder='Enter image url'/>
                  <Form.Text>Users can view the image when interacting with your chatbot</Form.Text>
                </Form.Group>
                <Form.Group style={styles.formMargin}>
                  <Form.Label style={styles.formBoldText}>Status</Form.Label>
                  <Form.Control as="textarea" value={status} onChange={handleStatusChange} placeholder='Enter chatbot status'/>
                  <Form.Text>Users can view status when searching for the chatbot</Form.Text>
                </Form.Group>
                {/* <Form.Group style={styles.formMargin}>
                  <Form.Label style={styles.formBoldText}>Persona</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    value={persona} 
                    onChange={handlePersonaChange} 
                    rows="4" 
                    placeholder=
                    "Describe your chatbots persona e.g.:&#13;&#13;You are a life long south side chicagoan"
                  />
                  <Form.Text>
                    Personas define the style and personality the chatbot adopts to respond to users
                  </Form.Text>
                </Form.Group> */}
                <Form.Group style={styles.formMargin}>
                  <Form.Label style={styles.formBoldText}>Instructions & Persona</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    value={instruction} 
                    onChange={handleInstructionChange} 
                    rows="6" 
                    placeholder=
                      "Enter instructions e.g.:&#13;&#13;You are a life long south side Chicagoan. People ask you questions about
                      Chicago and you respond using your local knowledge in the local vernacular. Be creative with 
                      your answers, which should feel as if a native Chicagoan wrote them. Use your general 
                      knowledge rather than relying on the context only!"
                  />
                  <Form.Text>
                    Instructions define how the chatbot responds to users
                  </Form.Text>
                </Form.Group>
                <Form.Group style={styles.formMargin}>
                  <Form.Label style={styles.formBoldText}>Examples</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    value={example} 
                    onChange={handleExampleChange} 
                    rows="6" 
                    placeholder=
                      "Enter examples e.g.:&#13;&#13;Q: What is the best pizza in Chicago?&#13;A: Lou Malnatis"
                  />
                  <Form.Text>
                    Example Questions & Answers responses that you expect from the chatbot
                  </Form.Text>
                </Form.Group>
                <Form.Group style={styles.formMargin}>
                  <Form.Label style={styles.formBoldText}>First Message</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    value={firstMessage} 
                    onChange={handleFirstMessageChange}
                    rows="3" 
                    placeholder=
                      "Enter examples e.g.:&#13;&#13;Q: What is the best pizza in Chicago?&#13;A: Lou Malnatis"
                  />
                  <Form.Text>
                    Example Questions & Answers responses that you expect from the chatbot
                  </Form.Text>
                </Form.Group>
                <Form.Group style={styles.formMargin}>
                  <Form.Label style={styles.formBoldText}>Database Schema & Details</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    value={dbFunction}
                    onChange={handleDbFunctionChange} 
                    rows="6" 
                    placeholder=
                      "Define your database schema e.g.:&#13;&#13;TBD"
                  />
                  <Form.Text>
                    Define your database schema here so that the chatbot can access the data
                  </Form.Text>
                </Form.Group>
                <FileTable authUser={authUser} cBot={sOption}/>

                {/* <Data authUser={authUser} cBot={sOption}/> */}
              </Container>
              <Container style={styles.createChatbotButtons}>
                { !chatBot ? (
                <Button style={styles.saveChatbotButton} onClick={handleSave}>Save Chatbot</Button>
                ) : (
                <Button style={styles.saveChatbotButton} onClick={handleUpdate}>Update Chatbot</Button>
                )}
                { chatBot && published===true ? (
                <Button style={styles.publishChatbotButton} onClick={handleUnpublish}>Unpublish Chatbot</Button>
                ) : (
                <Button style={styles.publishChatbotButton} onClick={handlePublish}>Publish Chatbot</Button>
                )}
                <Button style={styles.deleteChatbotButton} onClick={handleDelete}>Delete Chatbot</Button>
              </Container>
            </Form>
          </Container>
        </Container>
        <Container style={styles.testChatbotContainer}>
          <Container style={styles.titleContainer}>
            <h3 style={{fontWeight:'bold'}} >Test Chatbot</h3>
          </Container>
          <AnswerSectionHeader />
          { chatBot ? (
            <>
              <ChatScreen cBot={chatBot} uid={authUser.attributes.sub}/>
            </>
          ) : (
              <p style={styles.formTestStyle}>Select a chatbot to enable testing</p>
          )}
        </Container>
      </Container>
  );
}

const styles = { 
  formMargin: {
    margin: '5px',
    marginBottom: '15px',
  },
  formTestStyle: {
    height: '25%',
    fontWeight: 'bold',
  },
  formBoldText: {
    fontWeight: 'bold',
  },
  titleContainer: {
    height: '5%',
    margin: '5px',
    marginBottom: '15px',
  },
  createChatbotButtons: {
    color: 'black',
    fontWeight: 'bold',
    border: 'none',
    height: '5%',
    marginTop: '20px',
  },
  saveChatbotButton: {
    backgroundColor: 'orange',
    color: 'black',
    fontWeight: 'bold',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '5px',
  },
  publishChatbotButton: {
    backgroundColor: 'lightgreen',
    marginRight: '5px',
    color: 'black',
    fontWeight: 'bold',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteChatbotButton: {
    backgroundColor: 'pink',
    color: 'black',
    fontWeight: 'bold',
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
  },
  createChatbotContainer: {
    width: '50%',
    justifyContent: 'left',
    alignItems: 'left',
    marginRight: '10px',
    border: '1px solid lightgrey',
    backgroundColor: 'lightblue',
    height: '100%',
  },
  createChatbotBody: {
    height: '86%',
  },
  createChatbotBodyForm: {
    height: '100%',
  },
  formContainerOverflow: {
    height: '95%',
    overflow: 'auto',
  },
  parentContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'left',
    height: '75vh',
  },
  questionContainerOverflow: {
    height: '52%',
    overflow: 'auto',
  },
  testChatbotContainer: {
    width: '50%',
    justifyContent: 'left',
    alignItems: 'left',
    marginLeft: '10px',
    border: '1px solid lightgrey',
    backgroundColor: 'lightblue',
  },
};

export default ChatBotForm;
