import { Button, Container } from "react-bootstrap";
import React, { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { createChatRoom, updateChatRoom, createMessage } from "../graphql/mutations";
import { chatRoomsByChatbotIDSimple, listMessagesByChatRoom } from "../graphql/queries";
import Message from "./Message";
import AdSense from 'react-adsense';


const FormSection3 = ({ cBot, isButtonDisabled, chatbotID }) => {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [authUser, setAuthUser] = useState(null);
    // const [chatroom, setChatroom] = useState(null);
    // const [userId, setUserId] = useState(null);
    // const [chatroomID, setChatroomID] = useState(null);

    const scrollContainerRef = useRef();

    useEffect(() => {
        // Scroll to the bottom whenever new messages are added
        scrollToBottom();
      }, [messages]);

    const scrollToBottom = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
    };

    function processString(str) {
      // Check if the string starts with "?"
      if (str.startsWith("?")) {
        // Remove the "?" character
        str = str.substring(1);
      }
    
      // Remove leading and trailing whitespaces
      str = str.trim();
      return str;
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSend = async () => {
      // validate user
      if (loading) {
          return;
      }
  
      setLoading(true);

      if (!chatbotID) {
        alert("Select a chatroom")
        return;
      }
  
      if (message.length > 0) {
        const newMessage = {
            // chatroomID: chatroom.id,
            // text: message,
            // userID: userId,
            message: message,
            isMe: true,
        }

        setMessages((prevList) => [...prevList, newMessage]);
  
        // const newMessageData = await API.graphql(
        //     graphqlOperation(createMessage, { input: newMessage})
        // )
  
        // set the new message as LastMessage of the ChatRoom
        // could also optionally choose to show the AI response as the Last Message
        // await API.graphql(
        //   graphqlOperation(updateChatRoom, {
        //     input: {
        //       _version: chatroom._version,
        //       chatRoomLastMessageId: newMessageData.data.createMessage.id,
        //       id: chatroom.id,
        //     },
        //   })
        // );

        //alert("handleSend")
        
        // send the new message to vector db via the api
        const fetchAIResponse = async () => {
          
            // might need to modify "otherUser" below to "chatBotID" (or similar)
            
            // NOTE - TOGGLE THE response values below to switch from test lambda function to prod lambda function
            //const response = await API.get('chatapi', '/api/testchat/'+chatroom.chatbotID+'/'+chatroom.id+'/'+text)
    
            //   const response = await API.get('chatapi', '/api/prodchat/'+chatroom.chatbotID+'/'+chatroom.id+'/'+message)
            console.log("before fetchAIResponse")
            const response = await API.get('chatapi', '/api/prodchatnochatroom/'+chatbotID+'/'+message)
                return response;
        };
        console.log("after fetchAIResponse")
      
        // 
        fetchAIResponse().then((response) => {
          let r = null
          if (response.res.text) {
            r = response.res.text
          } else if (response.res.response) {
            r = response.res.response
          }
          
          if (r !== null) {
  
            // let t = processString(response.res.text)
            let t = processString(r)
            console.log("ai chatbot response: " + t)
            // const newAIMessage = {
            //       chatroomID: chatroom.id,
            //       text: t,
            //       //userID: authUser.attributes.sub,
            //       userID: userId,
            // }

            const newAIMessage = {
                message: t,
                isMe: false,
          }

        setMessages((prevList) => [...prevList, newAIMessage]);

        // API.graphql(graphqlOperation(createMessage, { input: newAIMessage}))
        }
        }   
        )
  
      setLoading(false)  
      setMessage("");

      // load chatroom messages

    //   API.graphql(graphqlOperation(listMessagesByChatRoom, { 
    //       chatroomID: cBot.id, 
    //       sortDirection: "DESC" 
    //     }))
    //   .then((result) => {
    //       setMessages(result.data?.listMessagesByChatRoom?.items)
    //   })

    } else {
      console.warn("Can't send empty message");
    }
    };

    return (
        <Container style={styles.adAndFormSectionContainer}>
            <Container style={styles.ad}>
                <AdSense.Google
                    client='ca-pub-4917609803522286'
                    slot='1589039255'
                    style={{ display: 'block' }}
                    format='auto'
                    responsive='true'
                />
            </Container>
            <Container style={styles.formSectionContainer}>
                <h1>{cBot.name}</h1>
                <Container style={styles.answerSection} ref={scrollContainerRef}>
                    
                    {messages.map((m) => (
                        <Message message={m}/>
                    ))}
                </Container>
                <Container style={styles.formSection}>
                    <Form.Group style={styles.formMargin}>
                        <Form.Control 
                        as="textarea" 
                        value={message} 
                        onChange={handleMessageChange} 
                        rows="4" 
                        placeholder=
                            "Ask me anything..."
                        />
                    </Form.Group>

                    <Button style={styles.button} onClick={handleSend} disabled={isButtonDisabled}>
                        Generate Response 🤖
                    </Button>
                </Container>
            </Container>
        </Container>
    )
}


const styles = { 
    adAndFormSectionContainer: {
        border: '4px solid black',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        height: '80vh',
        backgroundColor: 'lightgrey',
    },
    formSectionContainer: {
        //height: '100%',
        //width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'lightgrey',
        //flex: '0 0 90%',
        height: '72vh',
    },
    ad: {
        //height: '100px',
        //backgroundColor: 'red',
        width: '100%',
        //flex: '0 0 10%',
        height: '8vh',
    },
    button: {
      backgroundColor: 'black',
      margin: '5px',
      color: 'white',
      fontWeight: 'bold',
      border: 'none',
      //flex: '0 0 30%',
      //flexShrink: 0,
      height: '4vh',
    },
    formSection: {
        //height: '25%',
        width: '100%',
        //flex: '0 0 30%',
        //flexShrink: 0,
        height: '24vh',
    },
    answerSection: {
        //height: '70%',
        //width: '100%',
        //flex: 1,
        //flexDirection: 'row',
        //backgroundColor: 'red',
        display: 'flex',
        flexDirection: 'column',  
        overflowY: 'scroll',
        //flex: '1',
        height: '46vh',
        //flex: '0 0 58%',
        flexShrink: 0,
    
    },
  };

  

export default FormSection3;