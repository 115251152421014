import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter as Router } from "react-router-dom";
import HomePage from "../pages/Homepage";
import ChatspediaNavbar2 from "./UnauthenticatedNavbar";


const UnauthenticatedApp = ({ handleSignIn, handleNavbarUpdate, selectedOption }) => {
  return (
    <div>
        <Router>
        <ChatspediaNavbar2 handleSignIn={handleSignIn}/>
        </Router>
        <Container>
            <HomePage onUpdate={handleNavbarUpdate} selectedOption={selectedOption} />
            {/* <ChatBotForm sOption={selectedOption} /> */}
        </Container>
    </div>
  );
};

// const styles = {
//     headerText: {
//         //marginTop: 0,
//         textAlign: 'center',
//         backgroundColor: 'lightblue',
//         height: '93vh'
//       }
// };

export default UnauthenticatedApp;