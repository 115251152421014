import { Button, Container } from "react-bootstrap";
import React, { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { createChatRoom, updateChatRoom, createMessage } from "../graphql/mutations";
import { chatRoomsByChatbotIDSimple, listMessagesByChatRoom } from "../graphql/queries";
import Message from "./Message";


const FormSection2 = ({ cBot, uid }) => {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [authUser, setAuthUser] = useState(null);
    const [chatroom, setChatroom] = useState(null);
    const [userId, setUserId] = useState(null);

    const scrollContainerRef = useRef();

    useEffect(() => {
        console.log("CBOT: ", cBot)

    }, [cBot])

    useEffect(() => {
        // Scroll to the bottom whenever new messages are added
        scrollToBottom();
      }, [messages]);

      const scrollToBottom = () => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
          scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
      };

    // useEffect(() => {
    //   setUserId(uid)

    //   // get chatroom
    //   const getCR = async () => {
    //     const response = await API.graphql(
    //       graphqlOperation(chatRoomsByChatbotIDSimple, { 
    //         chatbotID: cBot.id,
    //         filter: {
    //           and: {
    //             userID: {
    //               eq: uid
    //             }
    //           },
    //         }
    //       })
    //     )
    //     setChatroom(response.data.chatRoomsByChatbotID.items[0]);
    //   }
    //   getCR();
    // }, [cBot])

    function processString(str) {
      // Check if the string starts with "?"
      if (str.startsWith("?")) {
        // Remove the "?" character
        str = str.substring(1);
      }
    
      // Remove leading and trailing whitespaces
      str = str.trim();
      return str;
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSend = async () => {
      // validate user
      if (loading) {
          return;
      }
  
      setLoading(true);
  
      if (message.length > 0) {
        const newMessage = {
            // chatroomID: chatroom.id,
            // text: message,
            // userID: userId,
            message: message,
            isMe: true,
        }

        setMessages((prevList) => [...prevList, newMessage]);
  
        // const newMessageData = await API.graphql(
        //     graphqlOperation(createMessage, { input: newMessage})
        // )
  
        // set the new message as LastMessage of the ChatRoom
        // could also optionally choose to show the AI response as the Last Message
        // await API.graphql(
        //   graphqlOperation(updateChatRoom, {
        //     input: {
        //       _version: chatroom._version,
        //       chatRoomLastMessageId: newMessageData.data.createMessage.id,
        //       id: chatroom.id,
        //     },
        //   })
        // );

        alert("handleSend")
        
        // send the new message to vector db via the api
        const fetchAIResponse = async () => {
          
          // might need to modify "otherUser" below to "chatBotID" (or similar)
          
          // NOTE - TOGGLE THE response values below to switch from test lambda function to prod lambda function
          //const response = await API.get('chatapi', '/api/testchat/'+chatroom.chatbotID+'/'+chatroom.id+'/'+text)
  
        //   const response = await API.get('chatapi', '/api/prodchat/'+chatroom.chatbotID+'/'+chatroom.id+'/'+message)
            
        const response = await API.get('chatapi', '/api/prodchatnochatroom/'+cBot.id+'/'+message)
            return response;
        };
      
        // 
        fetchAIResponse().then((response) => {
          let r = null
          if (response.res.text) {
            r = response.res.text
          } else if (response.res.response) {
            r = response.res.response
          }
          
          if (r !== null) {
  
            // let t = processString(response.res.text)
            let t = processString(r)
            console.log("ai chatbot response: " + t)
            // const newAIMessage = {
            //       chatroomID: chatroom.id,
            //       text: t,
            //       //userID: authUser.attributes.sub,
            //       userID: userId,
            // }

            const newAIMessage = {
                message: t,
                isMe: false,
          }

        setMessages((prevList) => [...prevList, newAIMessage]);

        // API.graphql(graphqlOperation(createMessage, { input: newAIMessage}))
        }
        }   
        )
  
      setLoading(false)  
      setMessage("");

      // load chatroom messages

    //   API.graphql(graphqlOperation(listMessagesByChatRoom, { 
    //       chatroomID: cBot.id, 
    //       sortDirection: "DESC" 
    //     }))
    //   .then((result) => {
    //       setMessages(result.data?.listMessagesByChatRoom?.items)
    //   })

    } else {
      console.warn("Can't send empty message");
    }
    };

    return (
        <>
          <Container style={styles.answerSection} ref={scrollContainerRef}>
              {messages.map((m) => (
                  <Message message={m}/>
              ))}
          </Container>
          <Container style={styles.formSection}>
              <Form.Group style={styles.formMargin}>
                <Form.Control 
                  as="textarea" 
                  value={message} 
                  onChange={handleMessageChange} 
                  rows="4" 
                  placeholder=
                    "Ask me anything..."
                />
              </Form.Group>

              <Button style={styles.button} onClick={handleSend}>
                  Generate Response 🤖
              </Button>
          </Container>
        </>
    )
}

const styles = { 
    button: {
      backgroundColor: 'black',
      margin: '5px',
      color: 'white',
      fontWeight: 'bold',
      border: 'none',
    },
    formSection: {
        height: '25%',
        width: '100%',
    },
    answerSection: {
        height: '75%',
        //width: '100%',
        //flex: 1,
        //flexDirection: 'row',
        //backgroundColor: 'red',
        display: 'flex',
        flexDirection: 'column',  
        overflowY: 'scroll',
    },
  };

  

export default FormSection2;