import { useState, useEffect } from 'react';
import { Storage } from '@aws-amplify/storage';
import { Table, Button } from 'react-bootstrap';
import { API, graphqlOperation } from 'aws-amplify';
import { updateChatBot } from '../graphql/mutations';

const PAGE_SIZE = 20

function FileTable({aUser, cBot}) {
  const [filesList, setFilesList] = useState([]);
  const [chatbot, setChatbot] = useState(null);

  useEffect(() => {
    Storage.list('', { level: 'private', pageSize: PAGE_SIZE, })
      .then(result => setFilesList(result.results))
      .catch(err => console.log(err));

    if (!chatbot) {
      setChatbot(cBot)
    }
    
  }, [chatbot, cBot]);

  function handlePreviewClick(key) {
    Storage.get(key, { level: 'private' })
      .then(url => window.open(url))
      .catch(err => console.log(err));
  }

  async function handleDeleteClick(key) {
    // note that the file is removed from s3 storage here
    // once removed from s3, a lambda job is triggered to remove the content from pinecone
    try {
      // remove file from s3 storage
      const result1 = await Storage.remove(key, { level: 'private' })

      //remove file from chatbot documents list in dynamodb
      const cb = {
        id: chatbot.data.getChatBot.id,
        documents: chatbot.data.getChatBot.documents.filter(doc => doc !== key)
      }

      const result2 = await API.graphql(graphqlOperation(updateChatBot, {input: cb}))
      
      // set file list to display on screen
      setFilesList(prevState => prevState.filter(file => file.key !== key));
      setChatbot(result2)

    } catch (err) {
      console.log(err)
    }
  }

  function handleFileChange(event) {
    setFilesList(event.target.files)
  }

  async function handleFileUpload() {
      try {
        const metadata = {
          'userID': aUser, // Replace with the actual user ID
          //'chatBotID': chatBot.data.chatBotsByUserID.items[0].id, // Replace with the actual chatBot ID
          'chatBotID': chatbot.data.chatBotsByUserID.items[0].id, // Replace with the actual chatBot ID

        };

        // for (let f in filesList) {
        for (var i = 0; i < filesList.length; i++) {
          var f = filesList[i];
        
          const result1 = await Storage.put(f.name, f, {
            // private means that users can only see their own documents
            level: 'private',
            progressCallback(progress) {
              console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            },
            metadata: metadata,
          })

          const chatbot = {
            // id: chatBot.data.chatBotsByUserID.items[0].id,
            id: chatbot.data.chatBotsByUserID.items[0].id,
            documents: result1.key,
            hasDocuments: true,
          }
          const result3 = await API.graphql(graphqlOperation(updateChatBot, {input: chatbot}))
          setChatbot(result3)
        }
      } catch (err) {
        console.log(err)
    }
  }

  return (
    <>
      <div>
          <h2>Your documents</h2>
          <Table striped hover>
          <thead>
              <tr>
              <th>File Name</th>
              {/* <th>Last Modified</th> */}
              <th>Preview</th>
              <th>Delete</th>
              </tr>
          </thead>
          <tbody>
              {Array.isArray(filesList) && filesList.map(file => (
              <tr key={file.key}>
                  <td>{file.key}</td>
                  {/* <td>{file.lastModified.toUTCString()}</td> */}
                  <td><Button onClick={() => handlePreviewClick(file)}>Preview</Button></td>
                  <td><Button onClick={() => handleDeleteClick(file)}>Delete</Button></td>
              </tr>
              // use the code below if we are exposing functionality to create more than one chatbot
              // <tr key={file.index}>
              //     <td>{file}</td>
              //     {/* <td>{file.lastModified.toUTCString()}</td> */}
              //     <td><Button onClick={() => handlePreviewClick(file)}>Preview</Button></td>
              //     <td><Button onClick={() => handleDeleteClick(file)}>Delete</Button></td>
              // </tr>
              ))}
          </tbody>
          </Table>
          <div>
        <h2>Add a document</h2>
          <input type="file" multiple onChange={handleFileChange} />
          <button onClick={handleFileUpload}>Upload File</button>
        </div>
      </div>
    </>
  );
}

// const style = {
//     'button': {
//         textDecoration: 'underline', 
//         color: 'blue', 
//         border: 'none', 
//         background: 'none', 
//         cursor: 'pointer' 
//     }
// }

export default FileTable;