import { Container } from 'react-bootstrap';

const App = () => {
    return (
        <Container style={styles.answerSectionHeader}>
            <p>
                Ask questions that you expect users to submit, evaluate the responses, & modify your chatbot inputs to improve the responses
            </p>
        </Container>
    );
};

const styles = {
    answerSectionHeader: {
    height: '10%'
  }
}

export default App;