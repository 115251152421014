import { Container } from "react-bootstrap";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {Auth} from "aws-amplify";
import { useState, useEffect } from "react";
dayjs.extend(relativeTime);

// const Message = ({ message }) => {
const Message = ({message}) => {
    const [isMe, setIsMe] = useState(false);

    useEffect(() => {
        const isMyMessage = async () => {
            setIsMe(message.isMe)
        };
    }, [])


    return (
        <Container 
        style={{
            ...styles.container,
            backgroundColor: message.isMe ? "#DBF3FA" : "white",
            alignSelf: message.isMe ? "flex-start" : "flex-end",            
        }}  
        >        
            <p style={styles.message}>{message.message}</p>
        </Container>
    );
};

const styles = {
  container: {
    margin: 5,
    padding: 10,
    borderRadius: 10,
    maxWidth: "66%",
    //shadowColor: '#000',
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.18,
    //shadowRadius: 1.0,
    //elevation: 1,
    //display: 'flex',
    //flex: 1,
    //width: '100%',
    //flexDirection: 'row',
  },
  message: {
    //alignSelf: "flex-end",
    color: "slategrey",
    textAlign: 'left',
  },
};

export default Message;